import React from "react";
import { graphql } from "gatsby";
import PageLayout from "./pageLayout";
import { CarouselImages } from "./carouselImages";
import { isMobile } from "../utils/isMobile";
import { Description } from "./description";
import { InfoShop } from "./info";

export default function ShopItem({ data }) {
  let { title, description, tags, titlePicture, meta, price } = data?.content;

  let carouselIndicators = true;

  if (isMobile()) {
    carouselIndicators = false;
  }

  let linkedTags = tags.join(", ");

  return (
    <PageLayout title={title}>
      <div className="d-flex justify-content-center mt-5">
        <div className={`container mr-2 mr-sm-4 ml-2 ml-sm-4`}>
          <CarouselImages index={0} images={[titlePicture, ]} paragraph={null} showIndicators={carouselIndicators}/>
          <InfoShop tags={linkedTags} title={title} price={price} meta={meta}/>
          <Description description={description} />
        </div>
      </div>
      <br />
    </PageLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    content(fields: { slug: { eq: $slug }}, parent: { internal: {description: {regex: "/shop/"}}}) {
      title
      description {
        childMdx {
          body
        }
      }
      tags
      meta
      price
      titlePicture {
        name
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    allContent(
      sort: { fields: added, order: DESC },
      filter: {
        hidden: {ne: true},
        parent: {internal: {description: {regex: "/shop/"}}}
      }) {
      edges {
        next {
          fields {
            slug
          }
        }
        previous {
          fields {
            slug
          }
        }
        node {
          title
        }
      }
    }
  }
`;
